
export const ENV = process.env.REACT_APP_ENV;
// export const isProduction = ENV === 'production';

export const API_URL_PROD = process.env.REACT_APP_API_URL_PROD ||'https://videos.biggfriends.com/images' ;
export const API_URL_STAGING = process.env.REACT_APP_API_URL_STAGING ||'https://node-staging.biggfriends.com/images' ;
export const API_URL_DEV = process.env.REACT_APP_API_URL_DEV ||'http://localhost:3004/images' ;


export const API_URL_NODE_PROD= process.env.REACT_APP_API_URL_NODE_PROD ||'https://videos.biggfriends.com/programming' ;
export const API_URL_NODE_STAGE= process.env.REACT_APP_API_URL_NODE_STAGE||'https://node-staging.biggfriends.com/programming' ;
export const API_URL_NODE_DEV= process.env.REACT_APP_API_URL_NODE_DEV||'http://localhost:3003/programming' ;



export const CDN_VIDEOS = process.env.REACT_APP_CDN_VIDEOS || 'https://bigg-backend-data-prod.s3.us-east-1.amazonaws.com';
export const CDN_VIDEOS_STAGE = process.env.REACT_APP_CDN_VIDEOS_STAGE || 'https://bigg-backend-data-uat.s3.us-east-2.amazonaws.com';

export const FOLDER_VIDEOS = process.env.REACT_APP_FOLDER_VIDEOS || 'movements';
export const ONDEMAND = process.env.REACT_APP_ONDEMAND;
export const AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
export const AWS_SECRET_ACCESS_KEY =   process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

export const AWS_BUCKET_NAME_PROD = process.env.REACT_APP_AWS_BUCKET_NAME_PROD || 'bigg-backend-data-prod';
export const AWS_BUCKET_NAME_STAGE = process.env.REACT_APP_AWS_BUCKET_NAME_STAGE || 'bigg-backend-data-uat';

export const AWS_BUCKET_REGION_PROD = process.env.REACT_APP_AWS_BUCKET_REGION_PROD || 'us-east-1';
export const AWS_BUCKET_REGION_STAGE = process.env.REACT_APP_AWS_BUCKET_REGION_STAGE|| 'us-east-2';

export const USER_VIDEOS = process.env.REACT_APP_USER_VIDEOS || 'bigg_admin';
export const PASS_VIDEOS = process.env.REACT_APP_PASS_VIDEOS || 'bigg4321';
export const USER_VIDEOS_SOPORTE = process.env.REACT_APP_USER_VIDEOS || 'soporte';
export const PASS_VIDEOS_SOPORTE = process.env.REACT_APP_PASS_VIDEOS || 'soportebigg4321';